<template>
  <div class="col-lg-1 col-sm-2 col-12 position-relative d-no">
    <div :class="getClass()">{{ number }}</div>
    <div class="vertical-border"></div>
  </div>
</template>

<script>
export default {
  props: ["withError", "currentPosition", "number"],
  methods: {
    getClass() {
      let className = "";
      if (this.withError) {
        className = this.withError ? "error" : "";
      } else {
        className = this.currentPosition == this.number ? "active" : "";
      }
      return ["numbers", className];
    }
  }
};
</script>
