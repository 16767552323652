<template>
<div class="wizard-experince-modal">
  <transition name="modal-fade">
    <form @submit.prevent="submit()">
      <div
        class="modal talent-wizard-modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="pt-0 pr-0 modal-header row">
              <div class="px-0 col-12 w-100 d-flex justify-content-end">
                <button type="button" class="px-0 btn-close" @click="close"></button>
              </div>
              <div class="col-12">
                <h3 class="modal-title main-color">{{ item ?  $t('talentWizard.editExperience') : $t('talentWizard.addExperience') }} </h3>
                <p>
                    {{$t('talentWizard.fillInDataRetaledToYourPreviousJobs')}}
                </p>
              </div>
            </div>

            <div class="modal-body no-border">
              <div class="row">
                <div class="mb-4 col-sm-6 col-12">
                  <label class="form-label required">
                    
                    {{$t('talentWizard.jobTitle')}}

                  </label>
                  <input
                    v-model="$v.formData.title.$model"
                    type="text"
                    class="form-control"
                    :placeholder="$t('talentWizard.jobTitle')"
                  />
                  <!-- ********* Validation Error*********** -->
                  <div class="mt-1">
                    <VulidateError
                      :validationField="$v.formData.title"
                      :params="[
                                 'required',
                                 'alphaNumericWithSpecialChars',
                                 { maxLength: { number: '100' } }
                            ]"
                    />
                  </div>
                  <!-- ************************************* -->
                </div>

                <div class="mb-4 col-sm-6 col-12">
                  <label class="form-label required">
                                      {{$t('talentWizard.company')}}
                  </label>
                  <input
                    v-model="$v.formData.company.$model"
                    type="text"
                    class="form-control"
                    :placeholder="$t('talentWizard.company')"
                  />
                  <!-- ********* Validation Error*********** -->
                  <div class="mt-1">
                    <VulidateError
                      :validationField="$v.formData.company"
                      :params="[
                                'required',
                                'alphaNumericWithSpecialChars',
                                { maxLength: { number: '100' } }
                        ]"
                    />
                  </div>
                  <!-- ************************************* -->
                </div>

                <div class="mb-4 col-sm-12">
                  <OneCheckBoxInput
                    :className="'form-check talent-profile-checkbox'"
                    :ClassLabel="'control control--checkbox'"
                    :labelText="$t('talentWizard.iCurrentlyWorkHere')"
                    :checked="formData.currently_work_in"
                    @input="inputChecked"
                  />
                </div>

                <div class="mb-4 col-lg-6 col-12">
                  <div class="row">
                    <div class="col-12">
                      <label class="form-label required">
                      {{$t('talentWizard.startDate')}}
                      </label>
                    </div>
                    <div class="mb-3 col-sm-6 col-12 mb-sm-0">
                      <MonthsDropDown
                        :className="'style-chooser'"
                        @input="setStartMonth"
                        :isDisabled="false"
                        ref="startMonthDropDownRef"
                        :default="formData.start_month"
                        :isRealTimeValidation="true"
                      />
                    </div>

                    <div class="mb-3 col-sm-6 col-12 mb-sm-0">
                      <YearsDropDown
                        :className="'style-chooser'"
                        @input="setStartYear"
                        :isDisabled="false"
                        ref="startYearDropDownRef"
                        :default="formData.start_year"
                         :isRealTimeValidation="true"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <span style="color:#FF6F59;">{{ futureDateError }}</span>
                    <span style="color:#FF6F59;" v-if="!futureDateError">{{ startDateError }}</span>
                  </div>
                </div>

                <div class="mb-4 col-lg-6 col-12">
                  <div class="row">
                    <div class="col-12">
                      <label class="form-label required">
                       {{$t('talentWizard.endDate')}}
                      </label>
                    </div>
                    <div class="mb-3 col-sm-6 col-12 mb-sm-0">
                      <MonthsDropDown
                        :className="'style-chooser'"
                        @input="setEndMonth"
                        :isDisabled="formData.currently_work_in"
                        :default="formData.end_month"
                        ref="endMonthDropDownRef"
                         :isRealTimeValidation="true"
                      />
                    </div>
                    <div class="mb-3 col-sm-6 col-12 mb-sm-0">
                      <YearsDropDown
                        :className="'style-chooser'"
                        @input="setEndYear"
                        :isDisabled="formData.currently_work_in"
                        :default="formData.end_year"
                        ref="endYearDropDownRef"
                         :isRealTimeValidation="true"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <span style="color:#FF6F59;">{{ endDateError }}</span>
                  </div>
                </div>

                <div class="mb-4 col-sm-12">
                  <label class="form-label required">
                  {{ $t('talentWizard.responsibility')}}
                  </label>
                  <textarea
                    v-model="$v.formData.description.$model"
                    class="form-control"
                    :placeholder="$t('talentWizard.responsibility')"
                  ></textarea>
                  <!-- ********* Validation Error*********** -->
                  <div class="mt-1">
                    <VulidateError
                      :validationField="$v.formData.description"
                      :params="[
                                 'required',
                                 { maxLength: { number: '5000' } }
                            ]"
                    />
                  </div>
                  <!-- ************************************* -->
                </div>
              </div>
            </div>
            <div class="modal-footer on-padding">
              <button type="button" class="btn cancelbtn btnstyleUpdate" data-bs-dismiss="modal" @click="close">
                {{ $t('talentWizard.cancel') }}
              </button>
              <button type="submit" class="btn btn-primary saveBtn" :disabled="validForm()">  {{ $t('talentWizard.save') }}</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </transition>
</div>
</template>

<script>
import "./FormModelExperiance.scss"
import OneCheckBoxInput from "../shared/OneCheckBoxInput/OneCheckBoxInput";
import MonthsDropDown from "../shared/MonthsDropdown/MonthsDropdown";
import YearsDropDown from "../shared/YearsDropdown/YearsDropdown";
import {alphaNumericWithSpecialChars} from "../../utils/utils"
import { validateStartDate, validateEndDate } from "../../functions/DateFunctions";
import VulidateError from "../shared/VulidateError/vulidateError";
import { required, maxLength } from "vuelidate/lib/validators";
import { isWorkable } from '../../utils/shared';

export default {
  props: ["item"],
  components: {
    MonthsDropDown,
    YearsDropDown,
    OneCheckBoxInput,
    VulidateError
  },
  mounted(){
    if(this.item) {
      this.mapData(this.item)
      this.$v.$touch()
      this.callvalidateStartDate()
      this.callvalidateEndDate()
      this.$refs.startMonthDropDownRef.$v.$touch()
      this.$refs.startYearDropDownRef.$v.$touch()
      if(!this.item.currently_work_in) {
       this.$refs.endMonthDropDownRef.$v.$touch()
       this.$refs.endYearDropDownRef.$v.$touch()
      }

    }
    
  },
  updated(){
    this.isWorkable()&&this.$v.$touch()
  },
  data() {
    return {
      startDateError: "",
      futureDateError: "",
      endDateError: "",
      formData: {
        title: "",
        company: "",
        description: "",
        currently_work_in: 0,
        start_month: "",
        start_year: "",
        end_month: "",
        end_year: ""
      }
    };
  },
  validations: {
    formData: {
      title: {
        required,
        maxLength: maxLength(100),
        alphaNumericWithSpecialChars
      },
      company: {
        required,
        maxLength: maxLength(100),
        alphaNumericWithSpecialChars
      },
      description: {
        required,
        maxLength: maxLength(5000)
      }
    }
  },
  watch: {
    item: function(val) {
      val ? this.mapData(val) : this.resetFormData();
    }
  },
  methods: {
    isWorkable,
    close() {
      this.$emit("close");
      this.item ? this.mapData(this.item) : this.resetFormData();
      this.$v.$reset();
    },
    handleReset(){
      this.$v.$touch();
    },
    inputChecked(value) {
      this.formData.currently_work_in = value;
      if (value == 1) {
        this.startDateError = "";
        this.endDateError = "";
        this.formData.end_month = "";
        this.formData.end_year = "";
      }
    },
    setStartMonth(value) {
      this.formData.start_month = value;
      this.callvalidateStartDate();
    },
    setStartYear(value) {
      this.formData.start_year = value;
      this.callvalidateStartDate();
    },
    setEndMonth(value) {
      this.formData.end_month = value;
      this.callvalidateEndDate();
    },
    setEndYear(value) {
      this.formData.end_year = value;
      this.callvalidateEndDate();
    },
    callvalidateStartDate() {
      let res = validateStartDate(
        this.formData.start_month,
        this.formData.start_year,
        this.formData.end_month,
        this.formData.end_year
      );
      this.startDateError = res.startDateError? this.$t(`validation.${[res.startDateError]}`) : ''
      this.endDateError = res.endDateError? this.$t(`validation.${[res.endDateError]}`): ''
      this.futureDateError = res.futureDateError? this.$t(`validation.${[res.futureDateError]}`) : ''
    },
    callvalidateEndDate() {
      let res = validateEndDate(
        this.formData.start_month,
        this.formData.start_year,
        this.formData.end_month,
        this.formData.end_year
      );

      this.startDateError = res.startDateError?  this.$t(`validation.${[res.startDateError]}`): ""
      this.endDateError = res.endDateError? this.$t(`validation.${[res.endDateError]}`): ""
    },
    validForm() {
      return (
        this.$v.$invalid ||
        !!this.startDateError ||
        !!this.futureDateError ||
        !!this.endDateError ||
        !this.formData.start_year ||
        !this.formData.start_month ||
        (!this.formData.currently_work_in &&
          (!this.formData.end_year || !this.formData.end_month))
      );
    },
    resetFormData() {
      this.formData.title = "";
      this.formData.company = "";
      this.formData.description = "";
      this.formData.currently_work_in = 0;
      this.formData.start_month = "";
      this.formData.start_year = "";
      this.formData.end_month = "";
      this.formData.end_year = "";
      this.startDateError = "";
      this.futureDateError = "";
      this.endDateError = "";
    },
    mapData(item) {
      let array = this.formData;
      Object.keys(item).map(function(key) {
        array[key] = item[key];
      });
      this.startDateError = "";
      this.futureDateError = "";
      this.endDateError = "";
    },
    async submit() {
      if (!this.item || (this.item.is_parsed_from_cv && !this.item.id  ) ) {
        try {
          await this.axios
            .post("/api/experiences", this.formData)
            .then(
              () => {
              this.$emit("getList"),
              this.resetFormData(),
              this.$v.$reset(),
              this.close()
              }
            );
        } catch (error) {
          console.log(error);
        }
      } else {
        try {

          if(!this.formData.parsing_id) delete this.formData.parsing_id

          
          await this.axios
            .put(`/api/experiences/${this.item.id}`, this.formData)
            .then(() => { 
              this.$emit("getList")
              this.close()
             });
        } catch (error) {
          console.log(error);
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
