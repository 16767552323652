<template>
  <div>
    <div class="mb-4 row">
      <!-- Add Button -->
      <div class="gap-2 mb-5 d-flex align-items-center">
        <h3 class="mb-0 me-auto">{{ $t('talentWizard.education') }}</h3>
        <button
          type="button"
          class="btn add-btn-not-dashed float-end wizard-btn-actions"
          @click="showModal()"
          :disabled="checkNumberOfEducations()"
        >
          <i class="bi bi-plus-circle"></i>
          <span>
          {{ $t('talentWizard.addEducation') }}
          </span>
        </button>
      </div>
      <!-- ----------- -->
    </div>

    <!-- List -->
    <div v-for="(item, index) in educations" :key="index">
      <div class="mb-4 col-12 align-items-center">
        <div
          class="experience-div position-relative"
          :class="{
            'experience-div__error': educationsHasAnEmptyRow.find(
              (e) => e.id == item.id && e.parsing_id == item.parsing_id
            ),
          }"
        >
          <div class="float-start me-3 experience-numer">{{ index + 1 }}</div>
          <div class="experienceText">
            <div class="dateOfWork">
              (<span v-if="getStartDate(item) == 'requiredField'"> - </span>
              <span v-else>{{ getStartDate(item) }} </span> -
              <span v-if="getEndDate(item) == 'requiredField'"> - </span>
              <span v-else>{{ getEndDate(item) }}</span
              >)

              <span
                class="company-name opacity"
                v-if="
                  educationsHasAnEmptyRow.find(
                    (e) => e.id == item.id && e.parsing_id == item.parsing_id
                  )
                "
              >
                <RequiredField :text="$t('talent.requiredField')" />
              </span>
            </div>
            <h5>
              <span v-if="item.university != null">{{ item.university }}</span>
              <span v-if="!item.university"> - </span>
              <span class="company-name" v-if="item.degree">{{
                item.degree
              }}</span>
            </h5>
            <p class="mb-0">
              <span v-if="item.study_field != null">{{
                item.study_field
              }}</span>
              <span v-if="!item.study_field"> - </span>
            </p>
          </div>

          <div class="gap-2 d-grid d-flex align-items-center absolute-btns">
            <button
              type="button"
              class="btn experience-btn"
              @click="
                deleteItem(item.is_parsed_from_cv ? item.parsing_id : item.id)
              "
            >
              <i class="flaticon-trash"></i>
            </button>
            <button
              type="button"
              class="btn experience-btn"
              @click="showModal(item)"
            >
              <i class="flaticon-edit"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- ----------- -->

    <!-- Add Model -->
    <FormModelEducation
      v-if="isModalVisible"
      :item="modelItem"
      @close="closeModal"
      @getList="getEducationList"
    />
    <!-- ------------ -->
    <div class="mt-5 mb-2">
      <NextPrevBtn
        :className="'d-grid gap-2 d-flex justify-content-md-end'"
        :step="stepNum"
        :educations="educations"
        :hasError="educationsHasAnEmptyRow.length ? true : false"
      />
    </div>
  </div>
</template>
<script>
import { isWorkable, checkProperties } from "../../utils/shared";
import RequiredField from "../shared/requiredField/requiredField.vue";
import NextPrevBtn from "../NextPrevWizardBtn/NextPrevWizardBtn.vue";
import FormModelEducation from "./FormModelEducation.vue";
import Months from "../../static/Months";

export default {
  props: ["stepNum"],
  components: { NextPrevBtn, FormModelEducation, RequiredField },
  data() {
    return {
      isModalVisible: false,
      modelItem: "",
      educations: [],
      isError: false,
    };
  },
  mounted() {
    this.getEducationList().finally(() => {
      
      let getParsing = JSON.parse(localStorage.getItem("getParsing"));
      if (getParsing && getParsing.educations) {
        this.getImportedCv().finally(() => {
          if (this.educations.length == 0) {
            this.isModalVisible = true;
          }
        });
      } else {
        if (this.educations.length == 0) {
          this.isModalVisible = true;
        }
      }
      
    });
  },
  computed: {
    educationsHasAnEmptyRow() {
      return this.educations.filter((education) => {
        let educationCheck = { ...education };

        delete educationCheck.degree
        
        if (educationCheck.currently) {
          delete educationCheck.end_month;
          delete educationCheck.end_year;
          delete educationCheck.parsing_id;
        }else {
          if(educationCheck.parsing_id ==  null) delete educationCheck.parsing_id;
        }

         const checkValidations = (ed) => {

         
          const pattern = /^[\x20-\x7E]*$/;

          if(ed.university.length > 100 || ed.study_field.length > 100 || ( education.degree && education.degree.length > 100) || !pattern.test(ed.university) || !pattern.test(ed.study_field) || (education.degree && !pattern.test(education.degree)) ) {
            return true
          } else {
            return false
          }
        }

        return this.checkProperties(educationCheck) || checkValidations(educationCheck);
      });
    },
  },
  methods: {
    isWorkable,
    checkProperties,
    async getImportedCv() {
      try {
        await this.axios.get("/api/users/importCv").then((res) => {
          res.data.data.parsed_cv.data.education.map((education) => {
            let newEducation = {
              is_parsed_from_cv: true,
              parsing_id: education?.id,
              university: education?.organization ? education.organization : "",
              study_field: education?.accreditation?.education
                ? education?.accreditation?.education
                : "",
              degree: education?.accreditation?.educationLevel
                ? education?.accreditation?.educationLevel
                : "",

              currently: education?.dates?.isCurrent ? 1 : 0,
              start_year: education?.dates?.startDate
                ? new Date(education?.dates?.startDate).getFullYear()
                : null,
              start_month: education?.dates?.startDate
                ? new Date(education?.dates?.startDate).getMonth() + 1
                : null,
              end_year: education?.dates?.completionDate
                ? new Date(education?.dates?.completionDate).getFullYear()
                : null,
              end_month: education?.dates?.completionDate
                ? new Date(education?.dates?.completionDate).getMonth() + 1
                : null,
            };

            if (
              !this.educations.find(
                (item) => item.parsing_id == newEducation.parsing_id
              )
            ) {
              this.educations.push(newEducation);
            }
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    setIsError() {
      this.isError = true;
    },
    showModal(item = null) {
      this.isModalVisible = true;
      this.modelItem = item;
    },
    closeModal() {
      this.isModalVisible = false;
      this.modelType = "";
    },
    async getEducationList() {
      try {
        await this.axios.get("/api/educations").then((res) => {
          if (this.modelItem) {
            this.educations = this.educations.filter(
              (education) => education.parsing_id != this.modelItem.parsing_id
            );
          }

          this.educations =  [...res.data.data, ...this.educations.filter(eItem => !res.data.data.find(e => e.id == eItem.id ))  ];

          this.isError = false;
        });
      } catch (error) {
        console.log(error);
      }
    },
    getStringMonth(id) {
      return Months.find((element) => element.id === id)?.name;
    },
    getStartDate(item) {
      if (this.isWorkable()) {
        if (item.start_month !== null && item.start_year !== null) {
          return this.getStringMonth(item.start_month) + " " + item.start_year;
        } else {
          return this.$t('talent.requiredField');
        }
      } else {
        if (item.start_month !== null && item.start_year !== null) {
          return this.getStringMonth(item.start_month) + " " + item.start_year;
        } else {
          return this.$t('talent.requiredField');
        }
      }
    },
    getEndDate(item) {
      if (this.isWorkable()) {
        if (item.currently) {
          return this.$t('sharebleLink.present');
        } else if (item.end_month == null || item.end_year == null) {
          return this.$t('talent.requiredField');
        } else {
          return this.getStringMonth(item.end_month) + " " + item.end_year;
        }
      } else {
        return item.currently
          ? this.$t('sharebleLink.present')
          :  (item.end_month == null || item.end_year == null) ? this.$t('talent.requiredField') : this.getStringMonth(item.end_month) + " " + item.end_year;
      }
    },
    deleteItem(id) {
      this.$confirm({
        message: this.$t('talentWizard.areYouSureYouWantToDeletThisSection') ,
        button: { no: this.$t('talentWizard.cancel'), yes: this.$t('talentWizard.delete') },
        callback: (confirm) => {
          if (confirm) {
            let education = this.educations.find(
              (item) => item.id == id || item.parsing_id == id
            );

            if (education.is_parsed_from_cv && !education.id) {
              this.educations = this.educations.filter(
                (item) => item.parsing_id !== id
              );
            } else {
              if (education.id) {
                this.deleteEducation(education.id);
              } else {
                this.deleteEducation(id);
              }
            }

            // this.deleteEducation(id).then(this.getEducationList());
          }
        },
      });
    },
    async deleteEducation(id) {
      try {
        await this.axios.delete(`/api/educations/${id}`).then(() => {
          this.educations = this.educations.filter((item) => item.id !== id);
          this.getEducationList();
        });
      } catch (error) {
        console.log(error);
      }
    },
    checkNumberOfEducations() {
      return this.educations.length == 25;
    },
  },
};
</script>

<style lang="scss">
.experience-div {
  @media (max-width: 425px) {
    padding: 20px 15px;
  }
}
.dateOfWork {
  display: flex;
  flex-direction: row;
  @media (max-width: 425px) {
    flex-direction: column;
    .company-name.opacity {
      margin-left: 10px;
    }
  }
}
</style>
