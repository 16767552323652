var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mb-4 row"},[_c('div',{staticClass:"gap-2 mb-5 d-flex align-items-center"},[_c('h3',{staticClass:"mb-0 me-auto"},[_vm._v(_vm._s(_vm.$t('talentWizard.education')))]),_c('button',{staticClass:"btn add-btn-not-dashed float-end wizard-btn-actions",attrs:{"type":"button","disabled":_vm.checkNumberOfEducations()},on:{"click":function($event){return _vm.showModal()}}},[_c('i',{staticClass:"bi bi-plus-circle"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('talentWizard.addEducation'))+" ")])])])]),_vm._l((_vm.educations),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"mb-4 col-12 align-items-center"},[_c('div',{staticClass:"experience-div position-relative",class:{
          'experience-div__error': _vm.educationsHasAnEmptyRow.find(
            (e) => e.id == item.id && e.parsing_id == item.parsing_id
          ),
        }},[_c('div',{staticClass:"float-start me-3 experience-numer"},[_vm._v(_vm._s(index + 1))]),_c('div',{staticClass:"experienceText"},[_c('div',{staticClass:"dateOfWork"},[_vm._v(" ("),(_vm.getStartDate(item) == 'requiredField')?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(_vm._s(_vm.getStartDate(item))+" ")]),_vm._v(" - "),(_vm.getEndDate(item) == 'requiredField')?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(_vm._s(_vm.getEndDate(item)))]),_vm._v(") "),(
                _vm.educationsHasAnEmptyRow.find(
                  (e) => e.id == item.id && e.parsing_id == item.parsing_id
                )
              )?_c('span',{staticClass:"company-name opacity"},[_c('RequiredField',{attrs:{"text":_vm.$t('talent.requiredField')}})],1):_vm._e()]),_c('h5',[(item.university != null)?_c('span',[_vm._v(_vm._s(item.university))]):_vm._e(),(!item.university)?_c('span',[_vm._v(" - ")]):_vm._e(),(item.degree)?_c('span',{staticClass:"company-name"},[_vm._v(_vm._s(item.degree))]):_vm._e()]),_c('p',{staticClass:"mb-0"},[(item.study_field != null)?_c('span',[_vm._v(_vm._s(item.study_field))]):_vm._e(),(!item.study_field)?_c('span',[_vm._v(" - ")]):_vm._e()])]),_c('div',{staticClass:"gap-2 d-grid d-flex align-items-center absolute-btns"},[_c('button',{staticClass:"btn experience-btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteItem(item.is_parsed_from_cv ? item.parsing_id : item.id)}}},[_c('i',{staticClass:"flaticon-trash"})]),_c('button',{staticClass:"btn experience-btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.showModal(item)}}},[_c('i',{staticClass:"flaticon-edit"})])])])])])}),(_vm.isModalVisible)?_c('FormModelEducation',{attrs:{"item":_vm.modelItem},on:{"close":_vm.closeModal,"getList":_vm.getEducationList}}):_vm._e(),_c('div',{staticClass:"mt-5 mb-2"},[_c('NextPrevBtn',{attrs:{"className":'d-grid gap-2 d-flex justify-content-md-end',"step":_vm.stepNum,"educations":_vm.educations,"hasError":_vm.educationsHasAnEmptyRow.length ? true : false}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }