<template>
<div class="wizard-skill-modal">
  <transition name="modal-fade">
    <div
      class="modal"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content skills-content">
          <div class="modal-header">
            <h3 class="modal-title" id="exampleModalLabel">
              {{$t('talentWizard.selectSkills')}}
            </h3>
            <button type="button" class="btn-close" @click="close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="mb-3 col-sm-6">
                <div class="mb-3 input-group">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('talentWizard.search')"
                    v-model="searchTerm"
                  />
                  <span class="input-group-text" id="addon-wrapping">
                    <i class="bi bi-search"></i>
                  </span>
                </div>

                <!---- Drop Down List ---->
              <Checkbox
               v-for="option in filterByTerm" :key="option.id"
              :label="option.name"
              :inputValue="option"
              v-model="checkedSkills"
              labelStyle="checkboxLabelStyle"
            />

                <!-- ---------------------->
              </div>
              <div class="col-sm-6 border-start">
                <span class="skills-label" v-for="skill in checkedSkills" :key="skill.id">
                  {{ skill.name }}
                  <button
                    type="button"
                    aria-label="Close"
                    @click="removeSkill(skill.id)"
                  >
                    <i class="bi bi-x"></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn poppins-font cancelbtn btnstyleUpdate" data-bs-dismiss="modal" @click="close">
               {{$t('talentWizard.cancel')}}
            </button>
            <button type="button" class="btn btn-primary saveBtn" @click="save()">
               {{$t('talentWizard.save')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</div>
</template>

<script>
import "./SkillsModalForm.scss"


import Checkbox from "../../components/shared/formControls/checkbox/checkbox.vue"

export default {
  props: ["options", "selectedSkills"],
  components: {
    Checkbox
  },
  data() {
    return {
      searchTerm: "",
      checkedSkills: [],
    };
  },
  watch: {
    options: {
      handler: function() {
        if (this.selectedSkills.length > 0) {
          this.checkedSkills = this.selectedSkills;
        } else {
          this.checkedSkills = [];
        }
        this.searchTerm = "";
      },
      deep: true
    }
  },
  computed: {
    filterByTerm() {
      return this.options.filter(option => {
        return option.name
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase());
      });
    }
  },
  methods: {
    save() {
      this.$emit("setSkills", this.checkedSkills);
      this.close();
    },
    close() {
      this.$emit("close");
      this.searchTerm=''
    },
    removeSkill(id) {
      let index = this.checkedSkills.findIndex(element => element.id === id);
      this.checkedSkills.splice(index, 1);
    }
  }
};
</script>
<style lang="scss" scoped>
.wizard-skill-modal  .input-group{
    flex-direction: row-reverse;
    direction: ltr;
}

.wizard-skill-modal  .input-group input {
      text-align: right;
}
</style>
