<template>
<div class="wizard-certificate-modal">
  <transition name="modal-fade">
    <form @submit.prevent="submit()">
      <div
        class="modal talent-wizard-modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header row pt-0 pr-0">
              <div class="col-12 px-0 w-100 d-flex justify-content-end"> 
              <button type="button" class="px-0 btn-close" @click="close"></button>
              </div>
              <div class="col-12">
                <h3 class="modal-title main-color">
                  {{ item ? $t('talentWizard.editCertificatesInfo') : $t('talentWizard.addCertificatesInfo') }} 
                  
                </h3>
                <p>
                  {{$t('talentWizard.fillInYourCertificatesDataToHighlightYourExpertise')}}
                </p>
              </div>
            </div>
            <div class="modal-body no-border">
              <div class="row">
                <div class="col-sm-6 col-12 mb-4">
                  <label class="form-label required">
                    {{$t('talentWizard.certificateName')}}
                  </label>
                  <input
                    v-model="$v.formData.name.$model"
                    type="text"
                    class="form-control"
                    :placeholder="$t('talentWizard.certificateName')"
                  />
                  <!-- ********* Validation Error*********** -->
                  <div class="mt-1">
                    <VulidateError
                      :validationField="$v.formData.name"
                      :params="[
                                'required',
                                'alphaNumSpace',
                                { maxLength: { number: '100' } }
                              ]"
                    />
                  </div>
                  <!-- ************************************* -->
                </div>

                <div class="col-sm-6 col-12 mb-4">
                  <label class="form-label">
                  {{$t('talentWizard.issuingOrganization')}}
                  </label>
                  <input
                    v-model="$v.formData.organization.$model"
                    type="text"
                    class="form-control"
                    :placeholder="$t('talentWizard.issuingOrganization')"
                  />
                  <!-- ********* Validation Error*********** -->
                  <div class="mt-1">
                    <VulidateError
                      :validationField="$v.formData.organization"
                      :params="[
                                'alphaNumSpace',
                                { maxLength: { number: '100' } }
                            ]"
                    />
                  </div>
                  <!-- ************************************* -->
                </div>

                <div class="col-sm-12 mb-4">
                  <OneCheckBoxInput
                    :className="'form-check talent-profile-checkbox'"
                    :ClassLabel="'control control--checkbox'"
                    :labelText="$t('talentWizard.thisCredentialDoesNotExist')"
                    :checked="formData.not_expire"
                    @input="inputChecked"
                  />
                </div>

                <div class="col-lg-6 col-12 mb-4">
                  <div class="row">
                    <div class="col-12">
                      <label class="form-label">
                      {{$t('talentWizard.issuingOrganization')}}
                      </label>
                    </div>
                    <div class="col-sm-6 col-12 mb-sm-0 mb-3">
                      <MonthsDropDown
                        :className="'style-chooser'"
                        @input="setStartMonth"
                        :isDisabled="false"
                        :isRequired="false"
                         ref="startMonthDropDownRef"
                        :default="formData.start_month"
                      />
                    </div>

                    <div class="col-sm-6 col-12 mb-sm-0 mb-3">
                      <YearsDropDown
                        :className="'style-chooser'"
                        @input="setStartYear"
                        :isDisabled="false"
                         :isRequired="false"
                         ref="startYearDropDownRef"
                        :default="formData.start_year"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <span style="color:#FF6F59;">{{ futureDateError }}</span>
                    <span style="color:#FF6F59;" v-if="!futureDateError">{{ startDateError }}</span>
                  </div>
                </div>

                <div class="col-lg-6 col-12 mb-4">
                  <div class="row">
                    <div class="col-12">
                      <label class="form-label">
                          {{$t('talentWizard.expirationDate')}}
                      </label>
                    </div>
                    <div class="col-sm-6 col-12 mb-sm-0 mb-3">
                      <MonthsDropDown
                        :className="'style-chooser'"
                        @input="setEndMonth"
                        :isDisabled="formData.not_expire"
                        ref="endMonthDropDownRef"
                         :isRequired="false"
                        :default="formData.end_month"
                      />
                    </div>
                    <div class="col-sm-6 col-12 mb-sm-0 mb-3">
                      <YearsDropDown
                        :className="'style-chooser'"
                        @input="setEndYear"
                        ref="endYearDropDownRef"
                         :isRequired="false"
                        :isDisabled="formData.not_expire"
                        :default="formData.end_year"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <span style="color:#FF6F59;">{{ endDateError }}</span>
                  </div>
                </div>

                <div class="col-sm-12 col-12 mb-4">
                  <label class="form-label">
                  {{$t('talentWizard.credentialId')}}
                  </label>
                  <input
                    v-model="$v.formData.identifier.$model"
                    type="text"
                    class="form-control"
                    :placeholder="$t('talentWizard.credentialId')"
                  />
                  <!-- ********* Validation Error*********** -->
                  <div class="mt-1">
                    <VulidateError
                      :validationField="$v.formData.identifier"
                      :params="[{ maxLength: { number: '100' } }]"
                    />
                  </div>
                  <!-- ************************************* -->
                </div>

                <div class="col-sm-12 col-12 mb-4">
                  <label class="form-label">
                   {{$t('talentWizard.credentialUrl')}}
                  </label>
                  <input
                    v-model="$v.formData.url.$model"
                    type="text"
                    class="form-control"
                    :placeholder="$t('talentWizard.credentialUrl')"
                    @input="(e) => handleChange(e)"
                  />
                  <!-- ********* Validation Error*********** -->
                  <div class="mt-1">
                     <span v-if="!isValidUrl" class="error">{{$t('talentWizard.invalidlink')}} </span>
                    <VulidateError
                      :validationField="$v.formData.url"
                      :params="[{maxLength: {number: '2000'}}]"
                    />
                  </div>
                  <!-- ************************************* -->
                </div>
              </div>
            </div>
            <div class="modal-footer on-padding">
              <button type="button" class="btn cancelbtn btnstyleUpdate" data-bs-dismiss="modal" @click="close">
               {{$t('talentWizard.cancel')}}
              </button>
              <button type="submit" class="btn btn-primary saveBtn" :disabled="validForm">
               {{$t('talentWizard.save')}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </transition>
</div>
</template>

<script>
import './FormModelCertificate.scss'
import OneCheckBoxInput from "../shared/OneCheckBoxInput/OneCheckBoxInput";
import MonthsDropDown from "../shared/MonthsDropdown/MonthsDropdown";
import YearsDropDown from "../shared/YearsDropdown/YearsDropdown";
import { validateStartDate, validateEndDate } from "../../functions/DateFunctions";
import VulidateError from "../shared/VulidateError/vulidateError.vue";
import { required, maxLength, helpers } from "vuelidate/lib/validators";
import { deepUrlValidation } from "../../utils/utils"
const alphaNumSpace = helpers.regex("alphaNumSpace", /^[A-Za-z0-9 ]+$/);

export default {
  props: ["item"],
  components: {
    MonthsDropDown,
    YearsDropDown,
    OneCheckBoxInput,
    VulidateError
  },
  data() {
    return {
      isValidUrl: true,
      startDateError: "",
      futureDateError: "",
      endDateError: "",
      formData: {
        name: "",
        organization: "",
        not_expire: 0,
        identifier: "",
        url: "",
        start_month: "",
        start_year: "",
        end_month: "",
        end_year: ""
      }
    };
  },
  validations: {
    formData: {
      name: {
        required,
        maxLength: maxLength(100),
        alphaNumSpace
      },
      organization: {
        maxLength: maxLength(100),
        alphaNumSpace
      },
      identifier: {
        maxLength: maxLength(100)
      },
      url: {
        maxLength: maxLength(2000)
      }
    }
  },
  mounted() {
    if (this.item) {
      this.mapData(this.item);
      this.$v.$touch();
      this.callvalidateStartDate();
      this.callvalidateEndDate();
      this.$refs.startMonthDropDownRef.$v.$touch();
      this.$refs.startYearDropDownRef.$v.$touch();
      if (!this.item.not_expire) {
        this.$refs.endMonthDropDownRef.$v.$touch();
        this.$refs.endYearDropDownRef.$v.$touch();
      }
    }
  },
  computed: {
     validForm() {
      return (
        this.$v.$invalid ||
        !!this.futureDateError ||
        !this.isValidUrl
      );
    },
  },
  watch: {
    item: function(val) {
      val ? this.mapData(val) : this.resetFormData();
    }
  },
  methods: {
    close() {
      this.$emit("close");
      this.item ? this.mapData(this.item) : this.resetFormData();
      this.$v.$reset();
    },
    inputChecked(value) {
      this.formData.not_expire = value;
      if (value == 1) {
        this.startDateError = "";
        this.endDateError = "";
        this.formData.end_month = "";
        this.formData.end_year = "";
      }
    },
    setStartMonth(value) {
      this.formData.start_month = value;
      this.callvalidateStartDate();
    },
    setStartYear(value) {
      this.formData.start_year = value;
      this.callvalidateStartDate();
    },
    setEndMonth(value) {
      this.formData.end_month = value;
      this.callvalidateEndDate();
    },
    setEndYear(value) {
      this.formData.end_year = value;
      this.callvalidateEndDate();
    },
    callvalidateStartDate() {
      let res = validateStartDate(
        this.formData.start_month,
        this.formData.start_year,
        this.formData.end_month,
        this.formData.end_year
      );
      this.startDateError = res.startDateError ? this.$t(`validation.${[res.startDateError]}`) : ''
      this.endDateError =  res.endDateError ?  this.$t(`validation.${[res.endDateError]}`) : ''
      this.futureDateError =  res.futureDateError ?this.$t(`validation.${[res.futureDateError]}`) : ''
    },
    callvalidateEndDate() {
      let res = validateEndDate(
        this.formData.start_month,
        this.formData.start_year,
        this.formData.end_month,
        this.formData.end_year
      );
      this.startDateError = res.startDateError ? this.$t(`validation.${[res.startDateError]}`) : ''
      this.endDateError =  res.endDateError ?  this.$t(`validation.${[res.endDateError]}`) : ''
    },
    resetFormData() {
      this.formData.name = "";
      this.formData.organization = "";
      this.formData.identifier = "";
      this.formData.url = "";
      this.formData.not_expire = 0;
      this.formData.start_month = "";
      this.formData.start_year = "";
      this.formData.end_month = "";
      this.formData.end_year = "";
      this.startDateError = "";
      this.futureDateError = "";
      this.endDateError = "";
    },
    mapData(item) {
      let array = this.formData;
      Object.keys(item).map(function(key) {
        array[key] = item[key];
      });
      this.startDateError = "";
      this.futureDateError = "";
      this.endDateError = "";
    },
    async submit() {
      if (!this.item  || !this.item.id) {
        try {
          await this.axios
            .post("/api/certifications", this.formData)
            .then(
              () => {
                this.$emit("getList"),
                this.resetFormData(),
                this.$v.$reset(),
                this.close()
              }
            );
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          await this.axios
            .put(`/api/certifications/${this.item.id}`, this.formData)
            .then(() => {
              this.$emit("getList")
              this.close()
            } );
        } catch (error) {
          console.log(error);
        }
      }
    },
    handleChange(e) {
      if (e.target.value == "") {
        this.isValidUrl = true;
      } else {
        const enteredValue=(e.target.value.startsWith("https://")||e.target.value.startsWith("http://"))?e.target.value :`https://${e.target.value}`
        this.isValidUrl = !deepUrlValidation(enteredValue);
      }
    },
  }
};
</script>
<style lang="scss" scoped></style>
