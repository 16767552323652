<template>
  <div class="mport-cv-talent-wrapper">
    <div class="row mb-4">
      <h3>
      {{$t('talentWizard.uploadYourCv')}}
      </h3>
      <p class="subtitle-p" style="color: #8d8d8d">
        
        {{$t('talentWizard.quicklyBuildYourProfileByUploadingYourCV')}}
      </p>
      <form @submit.prevent="submit()">
        <div style="height: 50px"></div>

        <div class="col-12 mb-4 align-items-center">
          <div class="d-flex upload-div align-items-center position-relative">
            <input
              type="file"
              @change="onFileChange($event)"
              @click="clear($event)"
              :value="cvValue"
            />
            <img
              class="float-start me-3"
              src="../../assets/images/ic_uploading.svg"
              alt
              width="50"
            />
            <div class="uploadText">
              <h5>
              {{$t('talentWizard.uploadYourCV')}}
              </h5>
              <p class="mb-0">
              {{$t('talentWizard.dragAndDropFilesHere')}}
              </p>
            </div>
          </div>
          <div class="mt-2">
            <span style="color: #ff6f59">{{ cvError }}</span>
          </div>
         
        </div>

        <!-- uploaded One -->
        <div class="col-12 mb-4 align-items-end">
          <div
            class="
              d-flex
              upload-div
              align-items-center
              uploaded
              position-relative
              d-flex
              align-items-end
            "
            v-if="cvName"
          >
            <img
              class="float-start me-3"
              src="../../assets/images/ic_uploading.svg"
              alt
              width="50"
            />
            <div class="uploadText">
              <h5 class="mb-0 d-flex align-items-end" v-tooltip="cvName">
                {{ windowSize > 700 ? cvName : truncate(cvName, 10) }}
                <span>
                  <button
                    type="button"
                    class="btn remove-cv remove-cv-btn"
                    @click="removeCv()"
                  >
                    <i class="flaticon-trash upload-remove-icon"></i>
                  </button>
                </span>
              </h5>
            </div>

            <div class="absolute-checked talent-absolute-checked">
              <i class="bi bi-check2-circle"></i>
            </div>
          </div>
        </div>

        <div  class="col-12 mb-4 align-items-end">
           <div class="mt-2" v-if="remaining_uploads > 0">
            <span style="color: #ff6f59" >
            {{$t('talentWizard.youHaveTimesRemainingToUploadYourResumeWithParsingFeature',{ number: remaining_uploads } )}}
            </span>
          </div>
           <div class="mt-2" v-if="remaining_uploads == 0">
            <span style="color: #ff6f59" >You have exceeded the maximum count for parsing your resume</span>
          </div>
        </div>
      </form>
    </div>
    <!-- ------------ -->
    <div class="mt-5 mb-2">
      <NextPrevBtn
        :className="'d-grid gap-3 d-flex justify-content-md-end'"
        :step="stepNum"
        :hasError="!this.cvName.length"
        :isNextDisabled="true"
        :formData="formData"
        :header="headers"
      />
    </div>
  </div>
</template>

<script>
import { truncate } from "../../utils/utils";
import NextPrevBtn from "../NextPrevWizardBtn/NextPrevWizardBtn";
import "./ImportCV.scss";

export default {
  props: ["stepNum"],
  components: { NextPrevBtn },
  data() {
    return {
      allowedExtentions: ["csv", "pdf", "doc", "docx", "txt"],
      headers: { "content-type": "multipart/form-data" },
      cvError: "",
      cvName: "",
      cv: "",
      windowSize: 2000,
      cvValue: "",
      remaining_uploads: null,
    };
  },
  computed: {
    formData: function () {
      if (typeof this.cv === "object") {
        let file = new FormData();
        file.append("cv", this.cv);
        file.append("parse", 1);
        return file;
      }
      return { cv: this.cv };
    },
  },
  mounted() {
    this.getImportedCv();
    this.windowSize = window.innerWidth;
  },
  methods: {
    truncate,
    async getImportedCv() {
      try {
        await this.axios.get("/api/users/importCv").then((res) => {
          this.cv = res.data ? res.data.data.path : "";
          this.cvName = res.data ? res.data.data.name : "";
          this.remaining_uploads = res.data ? res.data.data.remaining_uploads : null;
        });
      } catch (error) {
        console.log(error);
      }
    },
    async onFileChange(e) {
      this.removeCv();
      if (this.checkFileExtention(e.target.files[0].name)) {
        return;
      } else if (this.checkFileSize(e.target.files[0].size)) {
        return;
      } else {
        this.cv = e.target.files[0];
        this.cvName = this.cv.name;
        let loader = this.$loading.show({});
        try {
          await this.axios
            .post("/api/users/importCv", this.formData, this.headers)
            .then(() => {
              this.getImportedCv();
              let getParsing = {
                experiences:  true,
                educations: true,
                certificates: true
              }

              localStorage.setItem("getParsing", JSON.stringify(getParsing) )

              this.next();
            })
            .catch(() => loader.hide())
            .finally(() => loader.hide());

        } catch (error) {
          console.log(error);
        }
      }
    },
    removeCv() {
      this.cv = "";
      this.cvName = "";
      this.cvError = "";
    },
    checkFileExtention(fileName) {
      let fileExtention = fileName.split(".").pop();
      if (!this.allowedExtentions.includes(fileExtention)) {
        return (this.cvError = this.$t("validation.cvExtentions"));
      }
    },
    checkFileSize(fileSize) {
      if (fileSize > 1024 * 1024 * 5) {
        return (this.cvError = this.$t("validation.fileSize", {
          number: "5",
        }));
      }
    },
    hasError() {
      //work as skip button
      return false;
    },
    clear(event) {
      event.target.value = null;
    },
  },
};
</script>
