<template>
  <div class="radio-toolbar">
    <input v-model="inputVal" type="radio" id="yes" value="1" :disabled="disable_part_time_availability" />
    <label for="yes">{{ $t('talentWizard.yes') }}</label>

    <input v-model="inputVal" type="radio" id="no" value="0" :disabled="disable_part_time_availability"  />
    <label for="no">{{ $t('talentWizard.no') }}</label>
  </div>
</template>

<script>
export default {
  props: ["value", "disable_part_time_availability" ],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>

<style scoped src="./YesNoQuestion.css"></style>
