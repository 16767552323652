<template>
    <div>
        <HorizontalStepper :options="stepper" :currentStep="currentStep" />
        <!-- main-steps-body -->
        <div class="container position-relative z-index-1">
            <div class="main-steps-body">
                <div class="padding-100" id="stepper-items">
                    <transition name="slide" mode="out-in">
                        <component
                            v-if="currentStep == stepper[0].stepNum"
                            :stepNum="stepper[0].stepNum"
                            :is="stepper[0].content"
                        ></component>
                        <component
                            v-if="currentStep == stepper[1].stepNum"
                            :stepNum="stepper[1].stepNum"
                            :is="stepper[1].content"
                        ></component>
                        <component
                            v-if="currentStep == stepper[2].stepNum"
                            :stepNum="stepper[2].stepNum"
                            :is="stepper[2].content"
                        ></component>
                        <component
                            v-if="currentStep == stepper[3].stepNum"
                            :stepNum="stepper[3].stepNum"
                            :is="stepper[3].content"
                        ></component>
                        <component
                            v-if="currentStep == stepper[4].stepNum"
                            :stepNum="stepper[4].stepNum"
                            :is="stepper[4].content"
                        ></component>
                        <component
                            v-if="currentStep == stepper[5].stepNum"
                            :stepNum="stepper[5].stepNum"
                            :is="stepper[5].content"
                        ></component>
                        <component
                            v-if="currentStep == stepper[6].stepNum"
                            :stepNum="stepper[6].stepNum"
                            :is="stepper[6].content"
                        ></component>
                    </transition>
                </div>
            </div>
        </div>
        <!-- ./main-steps-body -->
    </div>
</template>

<script>
import HorizontalStepper from "../../components/HorizontalStepper/HorizontalStepper.vue";
import BasicInfo from "../../components/BasicInfo/BasicInfo.vue";
import Projects from "../../components/Projects/Projects.vue";
import Experience from "../../components/Experiance/Experiance.vue";
import Education from "../../components/Education/Education.vue";
import Certificate from "../../components/Certificate/Certificate.vue";
import Accounts from "../../components/Accounts/Accounts.vue";
import ImportCV from "../../components/ImportCV/ImportCV.vue";
import Steps from "../../enums/WizzardSteps";
import {scrollToTop} from "../../utils/utils"

import { mapGetters } from "vuex";

export default {
    name: "Wizard",
    components: { HorizontalStepper, BasicInfo, Projects, ImportCV, Experience, Certificate, Education, Accounts },
    mounted() {
     this.scrollToTop()
  },
   data() {
        return {
            ProfileStep: Steps.PROFILE,
            stepper: [
                {
                    stepNum: Steps.BASIC_INFO,
                    title: "basicInfo",
                    icon: "flaticon-ic-icon-active-1",
                    content: BasicInfo
                },
                {
                    stepNum: Steps.PROJECTS,
                    title: "projects",
                    icon: "flaticon-note",
                    content: Projects
                },
                {
                    stepNum: Steps.IMPORTCV,
                    title: "importCv",
                    icon: "flaticon-ic-icon-2",
                    content: ImportCV
                },
                {
                    stepNum: Steps.EXPERIANCE,
                    title: "experience",
                    icon: "flaticon-portfolios",
                    content: Experience
                },
                {
                    stepNum: Steps.EDUCATION,
                    title: "education",
                    icon: "flaticon-book",
                    content: Education
                },
                {
                    stepNum: Steps.CERTIFICATION,
                    title: "certificates",
                    icon: "flaticon-medal",
                    content: Certificate
                },
                {
                    stepNum: Steps.ACCOUNT,
                    title: "accounts",
                    icon: "flaticon-ic-icon-6",
                    content: Accounts
                }
            ]
        };
    },
     methods: {
    scrollToTop,
     },
    computed: {
        ...mapGetters({
            currentStep: "auth/getUserStep"
        })
    }
};
</script>
