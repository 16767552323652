var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"gap-2 d-flex mb-5 align-items-center"},[_c('h3',{staticClass:"me-auto mb-0"},[_vm._v(" "+_vm._s(_vm.$t('sharebleLink.certificates')))]),_c('button',{staticClass:"btn add-btn-not-dashed float-end wizard-btn-actions",attrs:{"type":"button","disabled":_vm.checkNumberOfCertificates()},on:{"click":function($event){return _vm.showModal()}}},[_c('i',{staticClass:"bi bi-plus-circle"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('talentWizard.addCertificatesInfo'))+" ")])])])]),_vm._l((_vm.certificates),function(item,index){return _c('div',{key:item.id},[_c('div',{staticClass:"col-12 mb-4 align-items-center"},[_c('div',{staticClass:"experience-div position-relative",class:{
          'experience-div__error': _vm.certificatesHasAnEmptyRow.find(
            (e) => e.name == item.name
          ),
        }},[_c('div',{staticClass:"float-start me-3 experience-numer"},[_vm._v(_vm._s(index + 1))]),_c('div',{staticClass:"experienceText"},[_c('div',{staticClass:"dateOfWork"},[(
                !(
                  _vm.getStartDate(item) == 'requiredField' &&
                  _vm.getEndDate(item) == 'requiredField'
                )
              )?_c('span',[_vm._v(" ("),(_vm.getStartDate(item) == 'requiredField')?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(_vm._s(_vm.getStartDate(item))+" ")]),_vm._v(" - "),(_vm.getEndDate(item) == 'requiredField')?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(_vm._s(_vm.getEndDate(item)))]),_vm._v(") ")]):_vm._e(),(
                _vm.certificatesHasAnEmptyRow.find((e) => e.name == item.name)
              )?_c('span',{staticClass:"company-name opacity"},[_c('RequiredField',{attrs:{"text":_vm.$t('talent.requiredField')}})],1):_vm._e()]),_c('h5',[_vm._v(" "+_vm._s(item.name)+" "),(item.organization)?_c('span',{staticClass:"company-name"},[_vm._v(_vm._s(item.organization))]):_vm._e()]),(item.identifier)?_c('p',{staticClass:"mb-0"},[_vm._v(" Credential ID "+_vm._s(item.identifier)+" ")]):_vm._e(),_c('p',[(item.url)?_c('a',{staticClass:"credential-link",attrs:{"href":_vm.getCredentialUrl(item)}},[_vm._v("See credential")]):_vm._e()])]),_c('div',{staticClass:"d-grid gap-2 d-flex align-items-center absolute-btns"},[_c('button',{staticClass:"btn experience-btn",attrs:{"type":"button"},on:{"click":function($event){item.id ? _vm.deleteItem(item.id) : _vm.deleteItemByName(item.name)}}},[_c('i',{staticClass:"flaticon-trash"})]),_c('button',{staticClass:"btn experience-btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.showModal(item)}}},[_c('i',{staticClass:"flaticon-edit"})])])])])])}),(_vm.isModalVisible)?_c('FormModelCertificate',{attrs:{"item":_vm.modelItem},on:{"close":_vm.closeModal,"getList":_vm.getCerificateList}}):_vm._e(),_c('div',{staticClass:"mt-5 mb-2"},[_c('NextPrevBtn',{attrs:{"className":'d-grid gap-2 d-flex justify-content-md-end',"step":_vm.stepNum,"certificates":_vm.certificates,"hasError":_vm.certificatesHasAnEmptyRow.length ? true : false}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }