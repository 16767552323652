<template>
  <div class="wizard-education-modal">
    <transition name="modal-fade">
      <form @submit.prevent="submit()">
        <div
          class="modal talent-wizard-modal"
          role="dialog"
          aria-labelledby="modalTitle"
          aria-describedby="modalDescription"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
          >
            <div class="modal-content">
              <div class="modal-header row pt-0 pr-0">
                <div class="col-12 px-0 w-100 d-flex justify-content-end">
                  <button
                    type="button"
                    class="px-0 btn-close"
                    @click="close"
                  ></button>
                </div>
                <div class="col-12">
                  <h3 class="modal-title main-color">
                    {{ item ? $t('talentWizard.editEducation') : $t('talentWizard.addEducation') }} 
                  </h3>
                  <p>
                   

                    {{$t('talentWizard.fillInEducationDegreesYouHaveEarnedAndAreCurrentlyPursuing')}}
                  </p>
                </div>
              </div>
              <div class="modal-body no-border">
                <div class="row">
                  <div class="col-sm-12 col-12 mb-4">
                    <label class="form-label required"
                      >
                      
                      {{$t('talentWizard.school')}} / {{$t('talentWizard.university')}}
                      </label
                    >
                    <input
                      v-model="$v.formData.university.$model"
                      type="text"
                      class="form-control"
                      :placeholder="$t('talentWizard.school') + ' / ' + $t('talentWizard.university')"
                    />
                    <!-- ********* Validation Error*********** -->
                    <div class="mt-1">
                      <VulidateError
                        :validationField="$v.formData.university"
                        :params="[
                          'required',
                          'alphaNumericWithSpecialChars',
                          { maxLength: { number: '100' } },
                        ]"
                      />
                    </div>
                    <!-- ************************************* -->
                  </div>

                  <div class="col-sm-6 col-12 mb-4">
                    <label class="form-label">
                     {{$t('talentWizard.degree')}} 
                    </label>
                    <input
                      v-model="$v.formData.degree.$model"
                      type="text"
                      class="form-control"
                      :placeholder="$t('talentWizard.degree')"
                    />
                    <!-- ********* Validation Error*********** -->
                    <div class="mt-1">
                      <VulidateError
                        :validationField="$v.formData.degree"
                        :params="[
                          'alphaNumericWithSpecialChars',
                          { maxLength: { number: '100' } },
                        ]"
                      />
                    </div>
                    <!-- ************************************* -->
                  </div>

                  <div class="col-sm-6 col-12 mb-4">
                    <label class="form-label required">
                    {{$t('talentWizard.fieldOfStudy')}}
                    </label>
                    <input
                      v-model="$v.formData.study_field.$model"
                      type="text"
                      class="form-control"
                      :placeholder="$t('talentWizard.fieldOfStudy')"
                    />
                    <!-- ********* Validation Error*********** -->
                    <div class="mt-1">
                      <VulidateError
                        :validationField="$v.formData.study_field"
                        :params="[
                          'required',
                          'alphaNumericWithSpecialChars',
                          { maxLength: { number: '100' } },
                        ]"
                      />
                    </div>
                    <!-- ************************************* -->
                  </div>

                  <div class="col-sm-12 mb-4">
                    <OneCheckBoxInput
                      :className="'form-check talent-profile-checkbox'"
                      :ClassLabel="'control control--checkbox'"
                      :labelText="$t('talentWizard.iAmUndergraduate')"
                      :checked="formData.currently"
                      @input="inputChecked"
                    />
                  </div>

                  <div class="col-lg-6 col-12 mb-4">
                    <div class="row">
                      <div class="col-12">
                        <label class="form-label required">
                        {{$t('talentWizard.startDate')}}
                        </label>
                      </div>
                      <div class="col-sm-6 col-12 mb-sm-0 mb-3">
                        <MonthsDropDown
                          :className="'style-chooser'"
                          @input="setStartMonth"
                          :isDisabled="false"
                          ref="startMonthDropDownRef"
                          :default="formData.start_month"
                          :isRealTimeValidation="true"
                        />
                      </div>

                      <div class="col-sm-6 col-12 mb-sm-0 mb-3">
                        <YearsDropDown
                          :className="'style-chooser'"
                          @input="setStartYear"
                          ref="startYearDropDownRef"
                          :isDisabled="false"
                          :default="formData.start_year"
                          :isRealTimeValidation="true"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <span style="color: #ff6f59">{{ futureDateError }}</span>
                      <span style="color: #ff6f59" v-if="!futureDateError">{{
                        startDateError
                      }}</span>
                    </div>
                  </div>

                  <div class="col-lg-6 col-12 mb-4">
                    <div class="row">
                      <div class="col-12">
                        <label class="form-label required">
                        {{$t('talentWizard.endDate')}}
                        </label>
                      </div>
                      <div class="col-sm-6 col-12 mb-sm-0 mb-3">
                        <MonthsDropDown
                          :className="'style-chooser'"
                          @input="setEndMonth"
                          ref="endMonthDropDownRef"
                          :isDisabled="formData.currently"
                          :default="formData.end_month"
                          :isRealTimeValidation="true"
                        />
                      </div>
                      <div class="col-sm-6 col-12 mb-sm-0 mb-3">
                        <YearsDropDown
                          :className="'style-chooser'"
                          @input="setEndYear"
                          ref="endYearDropDownRef"
                          :isDisabled="formData.currently"
                          :default="formData.end_year"
                          :isRealTimeValidation="true"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <span style="color: #ff6f59">{{ endDateError }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer on-padding">
                <button
                  type="button"
                  class="btn cancelbtn btnstyleUpdate"
                  data-bs-dismiss="modal"
                  @click="close"
                >
                  
                   {{$t('talentWizard.cancel')}}

                  
                </button>
                <button
                  type="submit"
                  class="btn btn-primary saveBtn"
                  :disabled="validForm()"
                >
                  
                   {{$t('talentWizard.save')}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </transition>
  </div>
</template>

<script>
import "./FormModelEducation.scss";
import { alphaNumericWithSpecialChars } from "../../utils/utils";
import { isWorkable } from "../../utils/shared";
import OneCheckBoxInput from "../shared/OneCheckBoxInput/OneCheckBoxInput";
import MonthsDropDown from "../shared/MonthsDropdown/MonthsDropdown";
import YearsDropDown from "../shared/YearsDropdown/YearsDropdown";
import {
  validateStartDate,
  validateEndDate,
} from "../../functions/DateFunctions";
import VulidateError from "../shared/VulidateError/vulidateError";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  props: ["item"],
  components: {
    MonthsDropDown,
    YearsDropDown,
    OneCheckBoxInput,
    VulidateError,
  },
  data() {
    return {
      startDateError: "",
      futureDateError: "",
      endDateError: "",
      formData: {
        university: "",
        study_field: "",
        degree: "",
        currently: 0,
        start_month: "",
        start_year: "",
        end_month: "",
        end_year: "",
      },
    };
  },
  mounted() {
    if (this.item) {
      this.mapData(this.item);
      this.$v.$touch();
      this.callvalidateStartDate();
      this.callvalidateEndDate();
      this.$refs.startMonthDropDownRef.$v.$touch();
      this.$refs.startYearDropDownRef.$v.$touch();
      if (!this.item.currently) {
        this.$refs.endMonthDropDownRef.$v.$touch();
        this.$refs.endYearDropDownRef.$v.$touch();
      }
    }
  },
  updated() {
    this.isWorkable() && this.$v.$touch();
  },
  validations: {
    formData: {
      university: {
        required,
        maxLength: maxLength(100),
        alphaNumericWithSpecialChars,
      },
      study_field: {
        required,
        maxLength: maxLength(100),
        alphaNumericWithSpecialChars,
      },
      degree: {
        maxLength: maxLength(100),
        alphaNumericWithSpecialChars,
      },
    },
  },
  watch: {
    item: function (val) {
      val ? this.mapData(val) : this.resetFormData();
    },
  },
  methods: {
    isWorkable,
    close() {
      this.$emit("close");
      this.item ? this.mapData(this.item) : this.resetFormData();
      this.$v.$reset();
    },
    inputChecked(value) {
      this.formData.currently = value;
      if (value == 1) {
        this.startDateError = "";
        this.endDateError = "";
        this.formData.end_month = "";
        this.formData.end_year = "";
      }
    },
    setStartMonth(value) {
      this.formData.start_month = value;
      this.callvalidateStartDate();
    },
    setStartYear(value) {
      this.formData.start_year = value;
      this.callvalidateStartDate();
    },
    setEndMonth(value) {
      this.formData.end_month = value;
      this.callvalidateEndDate();
    },
    setEndYear(value) {
      this.formData.end_year = value;
      this.callvalidateEndDate();
    },
    callvalidateStartDate() {
      let res = validateStartDate(
        this.formData.start_month,
        this.formData.start_year,
        this.formData.end_month,
        this.formData.end_year
      );
 
      this.startDateError = res.startDateError ? this.$t(`validation.${[res.startDateError]}`) : ''
      this.endDateError =  res.endDateError ?  this.$t(`validation.${[res.endDateError]}`) : ''
      this.futureDateError =  res.futureDateError ?this.$t(`validation.${[res.futureDateError]}`) : ''
    },
    callvalidateEndDate() {
      let res = validateEndDate(
        this.formData.start_month,
        this.formData.start_year,
        this.formData.end_month,
        this.formData.end_year
      );
      this.startDateError = res.startDateError ? this.$t(`validation.${[res.startDateError]}`) : ''
      this.endDateError =  res.endDateError ?  this.$t(`validation.${[res.endDateError]}`) : ''
    },
    validForm() {
      return (
        this.$v.$invalid ||
        !!this.startDateError ||
        !!this.futureDateError ||
        !!this.endDateError ||
        !this.formData.start_year ||
        !this.formData.start_month ||
        (!this.formData.currently &&
          (!this.formData.end_year || !this.formData.end_month))
      );
    },
    resetFormData() {
      this.formData.university = "";
      this.formData.study_field = "";
      this.formData.degree = "";
      this.formData.currently = 0;
      this.formData.start_month = "";
      this.formData.start_year = "";
      this.formData.end_month = "";
      this.formData.end_year = "";
      this.startDateError = "";
      this.futureDateError = "";
      this.endDateError = "";
    },
    mapData(item) {
      let array = this.formData;
      Object.keys(item).map(function (key) {
        array[key] = item[key];
      });
      this.startDateError = "";
      this.futureDateError = "";
      this.endDateError = "";
    },
    async submit() {
      if (!this.item || (this.item.is_parsed_from_cv && !this.item.id)) {
        try {
          await this.axios.post("/api/educations", this.formData).then(() => {
            this.$emit("getList"),
              this.resetFormData(),
              this.$v.$reset(),
              this.close();
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          if (!this.formData.parsing_id) delete this.formData.parsing_id;

          await this.axios
            .put(`/api/educations/${this.item.id}`, this.formData)
            .then(() => {
              this.$emit("getList"), this.close();
            });
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
