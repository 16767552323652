<template>
  <div>
    <div class="row mb-4">
      <!-- Add Button -->
      <div class="gap-2 d-flex mb-5 align-items-center">
        <h3 class="me-auto mb-0"> {{$t('talentWizard.experience') }}</h3>
        <button
          type="button"
          class="btn add-btn-not-dashed float-end wizard-btn-actions"
          @click="showModal()"
          :disabled="checkNumberOfExperiances()"
        >
          <i class="bi bi-plus-circle"></i>
          <span>
            {{$t('talentWizard.addExperience') }}
          </span>
        </button>
      </div>
      <!-- ----------- -->

      <!-- List -->
      <div v-for="(item, index) in experiances" :key="index">
        <div class="col-12 mb-4 align-items-center">
          <div
            class="experience-div position-relative"
            :class="{
              'experience-div__error': experienceHasAnEmptyRow.find(
                (e) => e.id == item.id && e.parsing_id == item.parsing_id
              ),
            }"
          >
            <div class="float-start me-3 experience-numer">{{ index + 1 }}</div>
            <div class="experienceText">
              <div class="dateOfWork">
                <span>
                  (<span v-if="getStartDate(item) == 'requiredField'"> - </span>
                  <span v-else>{{ getStartDate(item) }} </span> -
                  <span v-if="getEndDate(item) == 'requiredField'"> - </span>
                  <span v-else>{{ getEndDate(item) }}</span
                  >)
                </span>

                <span
                  class="company-name opacity"
                  v-if="
                    experienceHasAnEmptyRow.find(
                      (e) => e.id == item.id && e.parsing_id == item.parsing_id
                    )
                  "
                >
                  <RequiredField :text="$t('talent.requiredField')" />
                </span>
              </div>
              <h5>
                <span v-if="item.title != null"> {{ item.title }}</span>
                <span v-if="!item.title"> - </span>

                <span class="company-name" v-if="item.company != null">{{
                  item.company
                }}</span>
                <span class="" v-if="!item.company"> - </span>
              </h5>
              <p class="mb-0">
                <span v-if="item.description !== null">{{
                  item.description
                }}</span>
                <span v-if="!item.description"> - </span>
              </p>
            </div>

            <div class="d-grid gap-2 d-flex align-items-center absolute-btns">
              <button
                type="button"
                class="btn experience-btn"
                @click="
                  deleteItem(item.is_parsed_from_cv ? item.parsing_id : item.id)
                "
              >
                <i class="flaticon-trash"></i>
              </button>
              <button
                type="button"
                class="btn experience-btn"
                @click="showModal(item)"
              >
                <i class="flaticon-edit"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ------ -->

    <!-- Add Model -->
    <FormModelExperiance
      ref="experienceModalForm"
      v-if="isModalVisible"
      :item="modelItem"
      @close="closeModal"
      @getList="getExperianceList"
    />
    <!-- ------------ -->
    <div class="mt-5 mb-2">
      <NextPrevBtn
        :className="'d-grid gap-3 d-flex justify-content-md-end'"
        :step="stepNum"
        :hasError="experienceHasAnEmptyRow.length ? true : false"
        :experiances="experiances"
      />
    </div>
  </div>
</template>

<script>
import RequiredField from "../shared/requiredField/requiredField.vue";
import NextPrevBtn from "../NextPrevWizardBtn/NextPrevWizardBtn.vue";
import FormModelExperiance from "./FormModelExperiance.vue";
import Months from "../../static/Months";
import { isWorkable, checkProperties } from "../../utils/shared";
import "./FormModelExperiance.scss";

export default {
  props: ["stepNum"],
  components: { NextPrevBtn, FormModelExperiance, RequiredField },
  data() {
    return {
      isModalVisible: false,
      modelItem: "",
      experiances: [],
      isError: false,
    };
  },
  mounted() {
    this.getExperianceList().finally(() => {
      let getParsing = JSON.parse(localStorage.getItem("getParsing"));
      if (getParsing && getParsing.experiences) {
        this.getImportedCv().finally(() => {
          if (this.experiances.length == 0) {
            this.isModalVisible = true;
          }
        });
      } else {
        if (this.experiances.length == 0) {
          this.isModalVisible = true;
        }
      }
    });
  },
  computed: {
    experienceHasAnEmptyRow() {
      return this.experiances.filter((experience) => {
        let experienceCheck = { ...experience };

        if (experienceCheck.currently_work_in) {
          delete experienceCheck.end_month;
          delete experienceCheck.end_year;
          delete experienceCheck.parsing_id;
        } else {
          if (experienceCheck.parsing_id == null)
            delete experienceCheck.parsing_id;
        }

        const checkValidations = (ex) => {
          const pattern = /^[\x20-\x7E]*$/;

          if (
            ex.title.length > 100 ||
            ex.company.length > 100 ||
            ex.description.length > 5000 ||
            !pattern.test(ex.title) ||
            !pattern.test(ex.company)
          ) {
            return true;
          } else {
            return false;
          }
        };
        return (
          this.checkProperties(experienceCheck) ||
          checkValidations(experienceCheck)
        );
      });
    },
  },
  methods: {
    isWorkable,
    checkProperties,
    async getImportedCv() {
      try {
        await this.axios.get("/api/users/importCv").then((res) => {
          res.data.data.parsed_cv.data.workExperience.map((experiance) => {
            let newExperiance = {
              is_parsed_from_cv: true,
              parsing_id: experiance?.id,
              title: experiance?.jobTitle ? experiance.jobTitle : "",
              company: experiance.organization ? experiance.organization : "",
              description: experiance.jobDescription
                ? experiance.jobDescription
                : "",
              currently_work_in: experiance?.dates?.isCurrent ? 1 : 0,
              start_year: experiance?.dates?.startDate
                ? new Date(experiance?.dates?.startDate).getFullYear()
                : null,
              start_month: experiance?.dates?.startDate
                ? new Date(experiance?.dates?.startDate).getMonth() + 1
                : null,
              end_year: experiance?.dates?.endDate
                ? new Date(experiance?.dates?.endDate).getFullYear()
                : null,
              end_month: experiance?.dates?.endDate
                ? new Date(experiance?.dates?.endDate).getMonth() + 1
                : null,
            };

            if (
              !this.experiances.find(
                (item) => item.parsing_id == newExperiance.parsing_id
              )
            ) {
              this.experiances.push(newExperiance);
            }
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    setIsError() {
      // TODO:
      this.isError = true;
    },
    showModal(item = null) {
      this.isModalVisible = true;
      this.modelItem = item;
      this.isError = false;
      this.isWorkable() && this.$refs.experienceModalForm.handleReset();
    },
    closeModal() {
      this.isModalVisible = false;
      this.modelType = "";
      this.isWorkable() && this.$refs.experienceModalForm.handleReset();
    },
    async getExperianceList() {
      try {
        await this.axios.get("/api/experiences").then((res) => {
          if (this.modelItem) {
            this.experiances = this.experiances.filter(
              (experience) => experience.parsing_id != this.modelItem.parsing_id
            );
          }

          this.experiances = [
            ...res.data.data,
            ...this.experiances.filter(
              (eItem) => !res.data.data.find((e) => e.id == eItem.id)
            ),
          ];
          this.isError = false;
        });
      } catch (error) {
        console.log(error);
      }
    },
    addNew(data) {
      this.experiances.push(data);
    },
    getStringMonth(id) {
      return Months.find((element) => element.id === id)?.name;
    },
    getStartDate(item) {
      if (this.isWorkable()) {
        if (item.start_month !== null && item.start_year !== null) {
          return this.getStringMonth(item.start_month) + " " + item.start_year;
        } else {
          return this.$t('talent.requiredField');
        }
      } else {
        if (item.start_month !== null && item.start_year !== null) {
          return this.getStringMonth(item.start_month) + " " + item.start_year;
        } else {
          return this.$t('talent.requiredField');
        }
      }
    },
    getEndDate(item) {
      if (this.isWorkable()) {
        if (item.currently_work_in) {
          return this.$t('sharebleLink.present');
        } else if (item.end_month == null || item.end_year == null) {
          return this.$t('talent.requiredField');
        } else {
          return this.getStringMonth(item.end_month) + " " + item.end_year;
        }
      } else {
        return item.currently_work_in
          ? this.$t('sharebleLink.present')
          : item.end_month == null || item.end_year == null
          ? this.$t('talent.requiredField')
          : this.getStringMonth(item.end_month) + " " + item.end_year;
      }
    },
    deleteItem(id) {
      this.$confirm({
        message: this.$t('talentWizard.areYouSureYouWantToDeletThisSection') ,
        button: { no: this.$t('talentWizard.cancel'), yes: this.$t('talentWizard.delete') },
        callback: (confirm) => {
          if (confirm) {
            let experience = this.experiances.find(
              (item) => item.id == id || item.parsing_id == id
            );
           
            if (experience.is_parsed_from_cv && !experience.id) {
              this.experiances = this.experiances.filter(
                (item) => item.parsing_id !== id
              );
            } else {
              if (experience.id) {
                this.deleteExperiance(experience.id);
              } else {
                this.deleteExperiance(id);
              }
            }
            // this.deleteExperiance(id).then(this.getExperianceList());
          }
        },
      });
    },
    async deleteExperiance(id) {
      try {
        await this.axios.delete(`/api/experiences/${id}`).then(() => {
          this.experiances = this.experiances.filter((item) => item.id !== id);
          this.getExperianceList();
        });
      } catch (error) {
        console.log(error);
      }
    },
    checkNumberOfExperiances() {
      return this.experiances.length == 25;
    },
  },
};
</script>
<style lang="scss">
.experience-div {
  border: 1px solid #dfdede;
  border-radius: 10px;
  padding: 20px 30px;
  -webkit-box-shadow: 1px 1px 1px rgb(165 165 166 / 28%);
  box-shadow: #f8f6f6 0px 8px 24px;
}

.experience-div__error {
  border: 1px solid #dd636e;
}

.experience-numer {
  border: 1px solid #ddd;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #f6f6f6;
  border-radius: 4px;
  font-size: 17px;
  font-weight: 500;
}

.experienceText {
  padding-right: 100px;
  display: block;
  margin-left: 60px;
}

@media (max-width: 575px) {
  .experienceText {
    margin-left: 0;
    margin-bottom: 50px;
    padding-right: 0px;
  }
}

.dateOfWork {
  color: #a5a5a5;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 5px;
}

.experienceText p {
  color: #666;
  font-size: 14px;
  font-weight: 300;
}

.experienceText h5 {
  font-size: 17px;
}

.experienceText .dot {
  font-size: 30px;
  opacity: 0.2;
  line-height: 3px;
}

.experienceText .company-name {
  font-size: 15px;
  opacity: 0.4;
  position: relative;
  margin-left: 20px;
  padding-left: 0 !important;
}

.experienceText .company-name::after {
  content: "";
  width: 5px;
  height: 5px;
  background-color: rgba(216, 216, 216, 1);
  border-radius: 5px;
  position: absolute;
  left: -10px;
  top: 8px;
}

.experience-div {
  @media (max-width: 425px) {
    padding: 20px 15px;
  }
}
.dateOfWork {
  display: flex;
  flex-direction: row;
  @media (max-width: 425px) {
    flex-direction: column;
    .company-name.opacity {
      margin-left: 10px;
    }
  }
}
</style>
