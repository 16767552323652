<template>
  <div>
    <div class="row mb-4">
      <!-- Add Button -->
      <div class="gap-2 d-flex mb-5 align-items-center">
        <h3 class="me-auto mb-0"> {{ $t('sharebleLink.certificates') }}</h3>
        <button
          type="button"
          class="btn add-btn-not-dashed float-end wizard-btn-actions"
          @click="showModal()"
          :disabled="checkNumberOfCertificates()"
        >
          <i class="bi bi-plus-circle"></i>
          <span>
           {{ $t('talentWizard.addCertificatesInfo') }}
          </span>
        </button>
      </div>
      <!-- ----------- -->
    </div>

    <!-- List -->
    <div v-for="(item, index) in certificates" :key="item.id">
      <div class="col-12 mb-4 align-items-center">
        <div
          class="experience-div position-relative"
          :class="{
            'experience-div__error': certificatesHasAnEmptyRow.find(
              (e) => e.name == item.name
            ),
          }"
        >
          <div class="float-start me-3 experience-numer">{{ index + 1 }}</div>
          <div class="experienceText">
            <div class="dateOfWork">
              <span
                v-if="
                  !(
                    getStartDate(item) == 'requiredField' &&
                    getEndDate(item) == 'requiredField'
                  )
                "
              >
                (<span v-if="getStartDate(item) == 'requiredField'"> - </span>
                <span v-else>{{ getStartDate(item) }} </span> -
                <span v-if="getEndDate(item) == 'requiredField'"> - </span>
                <span v-else>{{ getEndDate(item) }}</span
                >)
              </span>

              <span
                class="company-name opacity"
                v-if="
                  certificatesHasAnEmptyRow.find((e) => e.name == item.name)
                "
              >
                <RequiredField :text="$t('talent.requiredField')" />
              </span>
            </div>

            <h5>
              {{ item.name }}

              <span class="company-name" v-if="item.organization">{{
                item.organization
              }}</span>
            </h5>
            <p class="mb-0" v-if="item.identifier">
              Credential ID {{ item.identifier }}
            </p>
            <p>
              <a
                class="credential-link"
                v-if="item.url"
                :href="getCredentialUrl(item)"
                >See credential</a
              >
            </p>
          </div>

          <div class="d-grid gap-2 d-flex align-items-center absolute-btns">
            <button
              type="button"
              class="btn experience-btn"
              @click="
                item.id ? deleteItem(item.id) : deleteItemByName(item.name)
              "
            >
              <i class="flaticon-trash"></i>
            </button>
            <button
              type="button"
              class="btn experience-btn"
              @click="showModal(item)"
            >
              <i class="flaticon-edit"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Model -->
    <FormModelCertificate
      v-if="isModalVisible"
      :item="modelItem"
      @close="closeModal"
      @getList="getCerificateList"
    />
    <!-- ------------ -->
    <div class="mt-5 mb-2">
      <NextPrevBtn
        :className="'d-grid gap-2 d-flex justify-content-md-end'"
        :step="stepNum"
        :certificates="certificates"
        :hasError="certificatesHasAnEmptyRow.length ? true : false"
      />
    </div>
  </div>
</template>

<script>
import { checkProperties } from "../../utils/shared";
import RequiredField from "../shared/requiredField/requiredField.vue";
import NextPrevBtn from "../NextPrevWizardBtn/NextPrevWizardBtn";
import FormModelCertificate from "./FormModelCertificate.vue";
import Months from "../../static/Months";
import { getUrl } from "../../functions/url";

export default {
  props: ["stepNum"],
  components: { NextPrevBtn, FormModelCertificate, RequiredField },
  data() {
    return {
      isModalVisible: false,
      modelItem: "",
      certificates: [],
    };
  },
  mounted() {
    this.getCerificateList().finally(() => {
      let getParsing = JSON.parse(localStorage.getItem("getParsing"));
      if (getParsing && getParsing.certificates) {
        this.getImportedCv().finally(() => {
          if (this.certificates.length == 0) {
            this.isModalVisible = true;
          }
        });
      } else {
        if (this.certificates.length == 0) {
          this.isModalVisible = true;
        }
      }
    });
  },
  computed: {
    certificatesHasAnEmptyRow() {
      return this.certificates.filter((certificate) => {
        let certificateCheck = { ...certificate };

        delete certificateCheck.end_month;
        delete certificateCheck.end_year;
        delete certificateCheck.start_month;
        delete certificateCheck.start_year;
        delete certificateCheck.organization;

        if (certificateCheck.not_expire) {
          delete certificateCheck.url;
          delete certificateCheck.identifier;
        } else {
          if (certificateCheck.url == null || !certificateCheck.url) {
            delete certificateCheck.url;
          }
          if (
            certificateCheck.identifier == null ||
            !certificateCheck.identifier
          ) {
            delete certificateCheck.identifier;
          }
        }

        const checkValidations = (c) => {
          const pattern = /^[\x20-\x7E]*$/;

          if (c.name.length > 100 || !pattern.test(c.name)) {
            return true;
          } else {
            return false;
          }
        };

        return (
          this.checkProperties(certificateCheck) ||
          checkValidations(certificateCheck)
        );
      });
    },
  },
  methods: {
    checkProperties,
    async getImportedCv() {
      try {
        await this.axios.get("/api/users/importCv").then((res) => {
          res.data.data.parsed_cv.data.certifications.map((certificate) => {
            let newCertificate = {
              name: certificate,
              organization: "",
              not_expire: 0,
              identifier: "",
              url: "",
              start_year: null,
              start_month: null,
              end_year: null,
              end_month: null,
            };

            if (
              !this.certificates.find(
                (item) => item.name == newCertificate.name
              )
            ) {
              this.certificates.push(newCertificate);
            }
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    showModal(item = null) {
      this.isModalVisible = true;
      this.modelItem = item;
    },
    closeModal() {
      this.isModalVisible = false;
      this.modelType = "";
    },
    async getCerificateList() {
      try {
        await this.axios.get("/api/certifications").then((res) => {
          if (this.modelItem) {
            this.certificates = this.certificates.filter(
              (certificate) => certificate.name != this.modelItem.name
            );
          }

          this.certificates = [
            ...res.data.data,
            ...this.certificates.filter(
              (cItem) => !res.data.data.find((e) => e.id == cItem.id)
            ),
          ];
        });
      } catch (error) {
        console.log(error);
      }
    },
    getStringMonth(id) {
      return Months.find((element) => element.id === id).name;
    },
    getStartDate(item) {
      if (item.start_month !== null && item.start_year !== null) {
        return this.getStringMonth(item.start_month) + " " + item.start_year;
      } else {
        return this.$t('talent.requiredField') 
      }
    },
    getEndDate(item) {
      if (item.not_expire) {
        return this.$t('talent.doesntExpire') 
      } else if (item.end_month == null || item.end_year == null) {
        return this.$t('talent.requiredField') 
      } else {
        return this.getStringMonth(item.end_month) + " " + item.end_year;
      }
    },
    deleteItem(id) {
      this.$confirm({
        message: this.$t("talentWizard.areYouSureYouWantToDeletThisSection"),
        button: {
          no: this.$t("talentWizard.cancel"),
          yes: this.$t("talentWizard.delete"),
        },
        callback: (confirm) => {
          if (confirm) {
            this.deleteCertificate(id);
            // this.deleteCertificate(id).then(this.getCerificateList());
          }
        },
      });
    },
    deleteItemByName(name) {
      this.$confirm({
        message: this.$t("talentWizard.areYouSureYouWantToDeletThisSection"),
        button: {
          no: this.$t("talentWizard.cancel"),
          yes: this.$t("talentWizard.delete"),
        },
        callback: (confirm) => {
          if (confirm) {
            this.certificates = this.certificates.filter(
              (certificate) => certificate.name != name
            );
            // this.deleteCertificate(id).then(this.getCerificateList());
          }
        },
      });
    },
    async deleteCertificate(id) {
      try {
        await this.axios.delete(`/api/certifications/${id}`).then(() => {
          this.certificates = this.certificates.filter(
            (item) => item.id !== id
          );
          this.getCerificateList();
        });
      } catch (error) {
        console.log(error);
      }
    },
    checkNumberOfCertificates() {
      return this.certificates.length == 25;
    },
    getCredentialUrl(item) {
      return getUrl(item.url);
    },
  },
};
</script>

<style lang="scss">
.experience-div {
  @media (max-width: 425px) {
    padding: 20px 15px;
  }
}
.dateOfWork {
  display: flex;
  flex-direction: row;
  @media (max-width: 425px) {
    flex-direction: column;
    .company-name.opacity {
      margin-left: 10px;
    }
  }
}
</style>
