<template>
  <div class="container position-relative z-index-1">
    <div class="icons-steps">
      <div class="row justify-content-center text-center">
        <div :key="item.stepNum" v-for="item in options" class="col">
          <div :class="['icon-body',currentStep >= item.stepNum ? 'active' : '']">
            <div class="icon-circle">
              <i :class="item.icon"></i>
            </div>
            <h6>{{ $t(`talentWizard.${[item.title]}`) }}</h6>
            <div class="icon-dot"></div>
            <div class="dot-border"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Stepper",
  props: ["options", "currentStep"]
};
</script>


